<template>
  <b-container fluid class="py-5 bg-white text-center">
    <b-row>
      <b-col>
        <div id="mermaid" class="mermaid" v-html="ad.configuration.graph">
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import mermaid from 'mermaid'

function delay (period) {
  return new Promise(resolve => {
    setTimeout(resolve, period)
  })
}

export default {
  components: {
  },
  computed: {
    ad: function () {
      return this.$store.state.appData
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    document.title = "UT - Diagram"
  },
  mounted: function () {
    this.draw()
  },
  data () {
    return {
    }
  },
  methods: {
    draw: async function () {
      await delay(50)
      mermaid.initialize({ theme: 'default', startOnLoad: false, securityLevel: 'loose' })
      const container = document.getElementById('mermaid')
      mermaid.init(this.ad.configuration.graph, container)
    }
  },
  watch: {
  }
}
</script>

<style>
</style>
